<script lang="ts">
  export default {
    name: 'Home Page',
    mounted() {
      const limit = document.body.offsetHeight + window.innerHeight * 2;
      const scrollIndicator = document.querySelector('.scroll-indicator') as HTMLElement;
      const scrollArrow = document.querySelector('.scroll-arrow') as HTMLElement;
      window.addEventListener('scroll', function() {
        const scrollPosition = window.scrollY;

        // Stop animation and transition color after scrolling past 400px
        if (scrollPosition > 400) {
          scrollArrow.classList.remove('animate-bounce');
          scrollIndicator.style.color = 'grey';
          // Curve opacity based on scroll position: ~90% near the bottom, ~20% at the top
        } else {
          scrollArrow.classList.add('animate-bounce');
          scrollIndicator.style.color = '';
          scrollIndicator.style.opacity ='1'
        }

        // Change direction of scroll arrow if at the bottom of the page
        const scrollFactor =  scrollPosition <= limit-window.innerHeight
        scrollIndicator.style.opacity = (scrollFactor ? 1 : scrollFactor ).toString();
        if (scrollPosition > limit - 1000) {
            scrollIndicator.style.color = 'white';
          scrollArrow.style.transform = 'rotate(180deg)';
        } else {
          scrollArrow.style.transform = 'none';
        }
      });
    }
  };
</script>
<template>
  <div class="bg-zinc-900 text-white">
    <div
      class="fixed top-0 text-primary-100 md:flex xs-hidden xl:left-3 scroll-indicator transition-opacity justify-around h-full"
      style="writing-mode: vertical-lr; z-index: 100;"
    >
      <hr class="block ml-2 h-1/3" style="border-top: none; border-left: 1px solid currentColor;" />
      <span class="animate-bounce transition-all scroll-arrow" style="color: currentColor;">
        scroll <span>→</span>
      </span>
      <hr class="block ml-2 h-1/3" style="border-top: none; border-left: 1px solid currentColor;" />
    </div>

    <div class="background-pattern absolute z-0 top-0 left-0 w-full h-full">
      <div class="background-pattern-wrapper absolute z-0 top-0 left-0 w-full h-full"></div>
      <div class="background-pattern-vertical absolute z-0 top-0 left-0 w-full h-full"></div>
    </div>

    <section class="relative pt-24 pb-44">
      <div class="landing-container relative z-10 flex gap-y-4 flex-col">
        <div class="space-y-3 w-fit">
          <h1 class="mx-auto font-black text-4xl md:text-6xl">
            <span class="relative heading-span-highlight" data-scroll-effect="fadeIn-top,0.3s,0s">
              Big Business Technology, Crafted for <em>Small</em> <em>Business</em> <em>Success</em>
            </span>
          </h1>
          <div class="flex gap-4 pt-4">
            <div class="flex gap-4 min-w-fit">
              <a href="https://zcal.co/kato-studio/30min" target="_blank"
                aria-label="Book Your Free Consultation CTA button">
                <button
                  class="inline-flex font-bold py-2.5 px-6 text-xl border border-primary stroke-white bg-primary hover:bg-primary-600 items-center focus:outline-none rounded mt-4 md:mt-0">
                  Book Your Free Consultation
                  <svg fill="none" class="w-6 h-full ml-1" stroke="inherit" stroke-linecap="round"
                    stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </button>
              </a>
            </div>
            <hr class="my-auto w-full opacity-50" />
          </div>
          <h2 class="py-4 md:py-8 sm:text-xl md:text-4xl text-zinc-300">
            Kato Studio empowers small businesses to thrive in competitive markets by delivering the same cutting-edge technology Fortune 100 companies use, tailored specifically to your unique needs.
            Our scalable web solutions are built to fuel your growth and give you the tools to succeed—no matter the size of your business.
          </h2>
        </div>
        <a class="flex hover:text-primary" href="mailto:hello@kato.studio" target="_blank">
          <iconify-icon class="text-primary p-1 mr-2" height="24" icon="octicon:mail-16"/>
          <h5 class="underline text-xl">hello@kato.studio</h5>
        </a>
      </div>
    </section>


    <!-- Our Approach Section -->
    <section class="relative pt-8 pb-40 flex h-fit mx-auto max-w-7xl" style=""
      data-scroll-effect="fadeIn-top,0.3s,0.1s">
      <div class="lines-bg-primary w-12 h-62"></div>
      <div class=" px-4 sm:px-6 lg:px-8">
        <h2 class="text-5xl font-bold tracking-tight relative">
          <span class="heading-span-highlight" data-scroll-effect="fadeIn-top,0.3s,0.2s">Our Approach</span>
        </h2>
        <p class="mt-4 text-xl max-w-5xl text-zinc-300" data-scroll-effect="fadeIn-top,0.3s,0.3s">
          At Kato Studio, we don’t just build websites; we build lasting partnerships. We focus on understanding your
          business, your challenges, and your goals. Our holistic approach combines cutting-edge technology with a deep
          understanding of the local Toronto market, so your business not only survives but thrives in a competitive
          landscape.
        </p>
        <ul class="mt-8 flex flex-col gap-6 text-left max-w-5xl text-zinc-300"
          data-scroll-effect="fadeIn-top,0.3s,0.4s">
          <li><strong class="text-primary-200">Discovery & Strategy:<br /></strong><span class="text-zinc-500"> -
            </span>We begin by understanding your unique challenges and opportunities, crafting a strategy that aligns
            with your business objectives.</li>
          <li><strong class="text-primary-200">Custom Development:<br /></strong><span class="text-zinc-500"> -
            </span>No templates here. We create web solutions designed to meet your specific needs—both now and as your
            business grows.</li>
          <li><strong class="text-primary-200">Long-Term Support:<br /></strong><span class="text-zinc-500"> -
            </span>Our relationship doesn’t end at launch. We provide ongoing strategy, insights, and technical support
            to keep your business ahead of the curve.</li>
        </ul>
        <div class="mt-8" data-scroll-effect="fadeIn-top,0.3s,0.5s">
          <a href="https://zcal.co/kato-studio/30min" arial-label="Let's talk CTA button" target="_blank">
            <!-- <preload class="py-2 px-5 text-md" /> -->
            <!-- <preload class="py-2.5 px-6 text-lg" /> -->
            <button
              class="inline-flex font-bold py-2.5 px-6 text-xl border border-primary stroke-white bg-primary hover:bg-primary-600 items-center focus:outline-none rounded mt-4 md:mt-0">
              Let's talk
              <svg fill="none" class="w-6 h-full ml-1" stroke="inherit" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>
          </a>

        </div>
      </div>
    </section>

    <!-- Us vs Them Section -->
    <div class="flex flex-col justify-center items-center lg:flex-row gap-8 xl:gap-16 px-2">
      <!-- Other Agencies Card -->
      <div class="max-w-md text-gray-300 bg-zinc-900 border w-full rounded-sm p-6"
        data-scroll-effect="fadeIn-top,1.8s,0.8s">
        <div class="text-center pb-10 text-gray-400">
          <iconify-icon height="54px" icon="streamline:graph-arrow-decrease"></iconify-icon>
        </div>
        <h2 class="text-4xl font-semibold mb-4">Other Agencies</h2>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="p-1 mr-2" height="24" icon="material-symbols:folder-limited-sharp"></iconify-icon>
            <h5 class="font-bold text-lg">Limited Customization</h5>
          </div>
          <p class="text-gray-400 pl-3">They rely on cookie-cutter templates, leaving small businesses struggling to
            stand out.</p>
        </div>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="p-1 mr-2" height="24" icon="mdi:clock"></iconify-icon>
            <h5 class="font-bold text-lg">Short-Term Approach</h5>
          </div>
          <p class="text-gray-400 pl-3">Once your website is launched, you're on your own without ongoing support or
            strategy.</p>
        </div>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="p-1 mr-2" height="24" icon="material-symbols:thumb-down"></iconify-icon>
            <h5 class="font-bold text-lg">Disconnected from Local Needs</h5>
          </div>
          <p class="text-gray-400 pl-3">They lack the local expertise needed to drive real growth in Toronto’s unique
            market.</p>
        </div>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="p-1 mr-2" height="24" icon="mdi:cog"></iconify-icon>
            <h5 class="font-bold text-lg">Overly Complicated Solutions</h5>
          </div>
          <p class="text-gray-400 pl-3">Complex systems with too much jargon, making it hard for small businesses to
            manage.</p>
        </div>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="p-1 mr-2" height="24" icon="mdi:layers"></iconify-icon>
            <h5 class="font-bold text-lg">Generic Technology</h5>
          </div>
          <p class="text-gray-400 pl-3">Basic, one-size-fits-all technology that can’t adapt as your business evolves.
          </p>
        </div>

      </div>

      <!-- Kato Studio Card -->
      <div class="max-w-md bg-zinc-900 border border-primary w-full rounded-sm p-6"
        data-scroll-effect="fadeIn-top,0.6s,0.3s">
        <div class="text-center pb-10 text-primary">
          <iconify-icon height="54px" icon="streamline:graph-arrow-increase"></iconify-icon>
        </div>
        <h2 class="text-4xl font-semibold mb-4">Kato Studio</h2>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="text-primary p-1 mr-2" height="24" icon="mdi:target"></iconify-icon>
            <h5 class="font-bold text-lg">Custom Solutions for Your Success</h5>
          </div>
          <p class="text-gray-300 pl-3">
            We bring enterprise-level technology, tailored to your specific goals so your business can compete and thrive.
          </p>
        </div>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="text-primary p-1 mr-2" height="24" icon="mdi:chart-line"></iconify-icon>
            <h5 class="font-bold text-lg">A Partner for the Long Run</h5>
          </div>
          <p class="text-gray-300 pl-3">
            We're with you every step of the way, offering ongoing guidance and strategic support for sustainable growth.
          </p>
        </div>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="text-primary p-1 mr-2" height="24" icon="mdi:city"></iconify-icon>
            <h5 class="font-bold text-lg">Toronto-Driven Expertise</h5>
          </div>
          <p class="text-gray-300 pl-3">
            We understand Toronto’s business landscape and what it takes to succeed in your local market.
          </p>
        </div>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="text-primary p-1 mr-2" height="24" icon="mdi:flash"></iconify-icon>
            <h5 class="font-bold text-lg">Clear, Actionable Solutions</h5>
          </div>
          <p class="text-gray-300 pl-3">
            Our approach is straightforward—no tech jargon, just practical, results-driven solutions.
          </p>
        </div>

        <div class="mb-4">
          <div class="flex">
            <iconify-icon class="text-primary p-1 mr-2" height="24" icon="mdi:trophy"></iconify-icon>
            <h5 class="font-bold text-lg">Fortune 100 Tech for Small Businesses</h5>
          </div>
          <p class="text-gray-300 pl-3">
            We adapt the same advanced tools used by industry giants to help your business grow.
          </p>
        </div>

      </div>
    </div>


    <!-- Why Partner with Us Section -->
    <section class="py-48" data-scroll-effect="fadeIn-top,0.3s,0.1s">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 class="text-5xl font-bold tracking-tight" data-scroll-effect="fadeIn-top,0.3s,0.2s">Why Partner with Us?
        </h2>
        <p class="mt-4 text-xl max-w-5xl text-zinc-300" data-scroll-effect="fadeIn-top,0.3s,0.3s">
          Partnering with Kato Studio means gaining access to technology and expertise that powers Fortune 100
          companies, but designed specifically for small businesses. We understand the unique challenges you face and
          are here to empower you every step of the way.
        </p>
        <div class="mt-14" data-scroll-effect="fadeIn-top,0.3s,0.4s">

          <a href="https://zcal.co/kato-studio/30min" arial-label="Book Your Free Consultation CTA button"
            target="_blank">

            <!-- <preload class="py-2 px-5 text-md" /> -->
            <!-- <preload class="py-2.5 px-6 text-lg" /> -->
            <button
              class="inline-flex font-bold py-2.5 px-6 text-xl border border-primary stroke-white bg-primary hover:bg-primary-600 items-center focus:outline-none rounded mt-4 md:mt-0">
              Book Your Free Consultation
              <svg fill="none" class="w-6 h-full ml-1" stroke="inherit" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2" viewBox="0 0 24 24">
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </button>

          </a>

        </div>
        <div class="flex pt-16 opacity-50">
          <p>Questions?</p>
          <hr class="w-2/3 my-auto ml-9" />
        </div>
        <a class="flex hover:text-primary pt-2" href="mailto:hello@kato.studio, hello@kato.studio" target="_blank">
          <iconify-icon class="text-primary p-1 mr-2" height="24" icon="octicon:mail-16"></iconify-icon>
          <h5 class="underline text-xl">hello@kato.studio</h5>
        </a>
      </div>
    </section>
  </div>
</template>
<style scoped>
  .landing-container {
    max-width: 74rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;
  }

  @keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-20px);
    }

    60% {
      transform: translateY(-10px);
    }
  }

  @keyframes animateBackgroundHeadingSpan {
    from {
      height: 0%;
    }

    to {
      height: 80%;
    }
  }

  @keyframes wave {

    0%,
    60%,
    100% {
      transform: rotate(0);
    }

    30% {
      transform: rotate(15deg);
    }
  }

  .heading-span-highlight em {
    position: relative;
    display: inline-block;
    margin: 0 0.5rem 0 0;
  }

  @media (min-width: 980px) {
    div.md:flex {
      display: flex;
    }
  }

  @media (max-width: 980px) {
    .xs-hidden {
      display: none;
    }
  }

  .heading-span-highlight em::before {
    animation: animateBackgroundHeadingSpan 0.3s linear 1 forwards 2s;
    content: '';
    z-index: -1;
    position: absolute;
    top: 10%;
    left: -0.5rem;
    width: calc(100% + 1.5rem);
    background: repeating-linear-gradient(-45deg, var(--primary-400), var(--primary-400) 5px, transparent 5px, transparent 10px);
  }
</style>
